//TODO: it would be better to decrease the specificity here
@include media(xl) {
    .o-category-tree {
        &.o-category-tree__stump {
            padding-left: 0;
            padding-right: 0;
        }

        &__header {
            &.o-category-tree__header--small {
                margin-left: 0;
            }
        }

        &__stump {
            .o-category-tree__block {
                margin-bottom: 0;
            }
        }
    }
}
;@import "sass-embedded-legacy-load-done:298";