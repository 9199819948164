@include media(md) {
    .o-cms-product-list {
        &__product-list-grid {
            grid-template-columns: repeat(2, 50%);
        }

        &__product-widget-container {
            padding: ($space * 2) 0 ($space * 8);
        }

        &__modal-panel {
            padding: ($space * 5) ($space * 5) 0 ($space * 5);

            .modal__close--top {
                right: 0;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:434";