.cms-last-bought-wishlist {
    &__title {
        margin-bottom: 8 * $space;
    }

    &__list-container {
        display: grid;
        row-gap: 8 * $space;
    }

    margin-bottom: $space * 14;
}

;@import "sass-embedded-legacy-load-done:569";