@include media(sm) {
    .picture-block {
        &__image {
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:719";