@include media(xxl) {
    .template-campaign,
    .template-basic,
    .template-plus,
    .template-premium {
        .o-category-tree__stump {
            margin-left: $space * 22;
            margin-right: $space * 22;
        }
    }
}

;@import "sass-embedded-legacy-load-done:328";