.cms-products-slider {
    margin-bottom: $space * 14;

    &__title {
        margin-bottom: $space * 8;
    }

    &__content-product {
        flex: 0 0 227px;
        margin-right: $space * 8;

        &:last-child {
            margin-right: 0;
        }

        .-tile-desktop-view {
            padding: 0;
        }
    }

    .t-slider-v2 {
        &__button-left.button,
        &__button-right.button {
            top: 45%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:713";