@include media(lg) {
    .picture-block {
        &__content {
            margin-bottom: $space * 14;
            justify-content: unset;
            flex-wrap: unset;
            margin-left: 0;
            margin-right: 0;
        }

        &__text {
            width: 100%;
        }

        &__text-container {
            padding: $space * 8;
        }

        &__image {
            height: 340px;

            &-small,
            &-big {
                height: 340px;
                width: unset;
                max-width: unset;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:725";