/* ------------------------------------ *\
    LISTS
\* ------------------------------------ */

ul,
ol {
    line-height: $line-height--list;
    &.list--reset { line-height: $base-line-height; }
}

ol {
    counter-reset: section;

    & > li {
        position: relative;
        padding-left: $space * 3;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            counter-increment: section;
            content: counter(section) ". ";
        }
    }
}

.list--inline > li {
    display: inline-block;
    line-height: $base-line-height;
}

;@import "sass-embedded-legacy-load-done:107";