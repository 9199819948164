/* ------------------------------------ *\
    MARGINS
\* ------------------------------------ */

@include generate-breakpoint-prefixes($class-name: '_m', $short-name: true) {
    &4 { margin: $space * 4 !important; }
    &t4 { margin-top: $space * 4 !important; }
    &r4 { margin-right: $space * 4 !important; }
    &b4 { margin-bottom: $space * 4 !important; }
    &l4 { margin-left: $space * 4 !important; }
    &y4 {
        margin-top: $space * 4 !important;
        margin-bottom: $space * 4 !important;
    }
    &x4 {
        margin-left: $space * 4 !important;
        margin-right: $space * 4 !important;
    }

    &3 { margin: $space * 3 !important; }
    &t3 { margin-top: $space * 3 !important; }
    &r3 { margin-right: $space * 3 !important; }
    &b3 { margin-bottom: $space * 3 !important; }
    &l3 { margin-left: $space * 3 !important; }
    &y3 {
        margin-top: $space * 3 !important;
        margin-bottom: $space * 3 !important;
    }
    &x3 {
        margin-left: $space * 3 !important;
        margin-right: $space * 3 !important;
    }

    &2 { margin: $space * 2 !important; }
    &t2 { margin-top: $space * 2 !important; }
    &r2 { margin-right: $space * 2 !important; }
    &b2 { margin-bottom: $space * 2 !important; }
    &l2 { margin-left: $space * 2 !important; }
    &y2 {
        margin-top: $space * 2 !important;
        margin-bottom: $space * 2 !important;
    }
    &x2 {
        margin-left: $space * 2 !important;
        margin-right: $space * 2 !important;
    }

    &1 { margin: $space !important; }
    &t1 { margin-top: $space !important; }
    &r1 { margin-right: $space !important; }
    &b1 { margin-bottom: $space !important; }
    &l1 { margin-left: $space !important; }
    &y1 {
        margin-top: $space !important;
        margin-bottom: $space !important;
    }
    &x1 {
        margin-left: $space !important;
        margin-right: $space !important;
    }

    &0 { margin: 0 !important; }
    &t0 { margin-top: 0 !important; }
    &r0 { margin-right: 0 !important; }
    &b0 { margin-bottom: 0 !important; }
    &l0 { margin-left: 0 !important; }
    &x0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    &y0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    &x-1 {
        margin-left: -$space !important;
        margin-right: -$space !important;
    }

    &l-auto {
        margin-left: auto !important;
    }
}

;@import "sass-embedded-legacy-load-done:120";