@include media(xl) {
    .m-rich-text {
        &__header-container {
            padding: 0 ($space * 8);
        }

        &__content {
            &--without-header {
                margin-top: $space * 20;
            }
        }
    }
}


;@import "sass-embedded-legacy-load-done:382";