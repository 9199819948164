/* ------------------------------------ *\
    ICONS
\* ------------------------------------ */

.img--xs {
    width: 24px;
    height: 24px;
}

.img--sm {
    width: 32px;
    height: 32px;
}

.img--md {
    width: 40px;
    height: 40px;
}

.img--lg {
    width: 70px;
    height: 70px;
}

;@import "sass-embedded-legacy-load-done:89";