@include media(lg) {
    .cms-products-slider {
        margin-bottom: $space * 28;

        &__title {
            margin-bottom: $space * 14;
        }
    }
}

;@import "sass-embedded-legacy-load-done:725";