.filter-modal {

    &__wrapper {
        display: none;
    }

    &__header {
        display: block;
        margin-bottom: $space * 8;

        @include text-variant(extra);
    }

    &__panel {
        padding-bottom: $space * 6;
        border-bottom: 1px solid $grey--medium;

        .t-panel-v2__body {
            padding: 0;
        }
    }

    .m-title-header__container.collapsed + & {
        &__sorting-value {
            display: block;
        }
    }

    .m-title-header__container {
        padding: 0;
        margin-bottom: $space * 3;
    }

    .m-title-header {
        font-size: $font-size--medium;
        margin-bottom: 0;
    }

    .m-title-header__button--right .fa {
        font-size: $font-size--icon;
        color: $grey--dark;
    }

    &__sorting {
        margin-left: -$space * 3;

        .form__field--radio {
            border-bottom: none;
        }

        label {
            font-size: $font-size--medium;
            line-height: $line-height--small;
        }

        &-value {
            display: none;
            color: $blue;
            margin-top: -$space;
            margin-bottom: 0 !important;
        }
    }
}

;@import "sass-embedded-legacy-load-done:437";