/* ------------------------------------ *\
    GRID COLUMNS
\* ------------------------------------ */


//.column classes
@mixin column-class($prefix, $columns) {
    @for $i from 1 through ($columns - 1) {
        .#{$prefix}_c#{$i} { width: percentage(calc($i / $columns)); }
    }

    .#{$prefix}_fit { width: 100%; }
    .#{$prefix}_c-auto { width: auto; }
}

@include column-class('', 6); //Default sm

@each $break-point, $columns in $grid-columns {
    @include media($break-point) {
        @include column-class($break-point, $columns);
    }
}


//.hidden
@include generate-breakpoint-prefixes($class-name: 'hidden') {
    & {
        visibility: hidden;
        display: none !important;
    }
}

//.shown
@include generate-breakpoint-prefixes($class-name: 'shown') {
    & {
        visibility: visible;
        display: block !important;
    }
}

@include generate-breakpoint-prefixes($class-name: 'shown--inline') {
    & {
        visibility: visible;
        display: inline-block !important;
    }
}

@include generate-breakpoint-prefixes($class-name: 'shown--flex') {
    & {
        visibility: visible;
        display: flex !important;
    }
}

;@import "sass-embedded-legacy-load-done:71";