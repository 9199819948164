/* ------------------------------------ *\
    HELPERS
\* ------------------------------------ */

//.stretch
.stretch {
    margin-left: -($space * 2);
    margin-right: -($space * 2);

    @include media(lg) {
        margin-left: inherit;
        margin-right: inherit;
    }
}

//float right/left
@include generate-breakpoint-prefixes($class-name: 'float') {
    &--right { float: right; }
    &--left { float: left; }
    &--none { float: none; }
}

//display block
.block { display: block; }

//position relative
.relative { position: relative; }

//position fixed
.fixed { position: fixed; }

//display inline-block
.inline--block { display: inline-block !important; }

//center
.center,
.center--horizontal,
.center--vertical { position: absolute !important; }

.center {
    top: 50% !important;
    left: 50% !important;
    transform: translate3d(-50%, -50%, 0) !important;
}

.center--horizontal {
    left: 50% !important;
    transform: translate3d(-50%, 0, 0) !important;
}

.center--vertical {
    top: 50% !important;
    transform: translate3d(0, -50%, 0) !important;
}

.center--margin { margin: 0 auto; }

//cursors
.pointer { cursor: pointer; }

//disable pointer events
.no-click { pointer-events: none; }

//z-index layers
._z1 { z-index: $z1; }
._z2 { z-index: $z2; }
._z3 { z-index: $z3; }
._z4 { z-index: $z4; }

//overflow
.o-visible {
    overflow: visible !important;
}

;@import "sass-embedded-legacy-load-done:88";