@include media(lg) {
    .cms-featured-categories {
        margin-bottom: $space * 28;

        &.-hide-desktop {
            display: none;
        }
    }
}

;@import "sass-embedded-legacy-load-done:716";