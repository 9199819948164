@include media(lg) {
    .third-picture-block {
        &__content {
            margin-bottom: $space * 20;
        }
        &__text {
            padding-left: $space * 14;
        }
    }
}

;@import "sass-embedded-legacy-load-done:495";