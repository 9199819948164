@include media(lg) {
    .o-cms-video {
        &__headline-container {
            &--after-cta-button {
                display: flex;
                text-align: left;
            }
        }

        &__container {
            margin-bottom: $space * 14;
        }

    }

    .template-campaign {
        .o-cms-video {
            &__container {
                margin-bottom: $space * 14;

                &--any-where-else,
                &--after-cta-button {
                    margin-top: $space * 14;
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:523";