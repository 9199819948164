/* ------------------------------------ *\
    BLOG POST
\* ------------------------------------ */

.template-blogpost,
.table-of-contents__container,
.related-stories__container {
    padding-left: $space * 4;
    padding-right: $space * 4;
}

;@import "sass-embedded-legacy-load-done:251";