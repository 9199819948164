.loading-indicator {
    align-items: center;
    display: none;
    min-height: 50vh;
    justify-content: center;
    width: 100%;
    @include text-variant(large);
    color: $blue;

    &.loading {
        display: flex;
    }

    &.loading + div {
        display: none;
    }
}

;@import "sass-embedded-legacy-load-done:343";